import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import "../components/dashboard-card/dashboard.scss"
import createBillingDashboardSession from '../utils/createBillingDashboardSession';
import dashboardLogo from "../assets/dashboard-logo.svg";
import { login, logout, isAuthenticated, getUserInfo } from "../utils/auth"
import Loading from '../components/CustomerArea/Loading';
import Profile from '../components/Profile';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Button as BaseButton } from '../components/shared/Buttons';
import {
    breakpoints,
    colors,
    fonts,
    spacing,
    dimensions
  } from '../utils/styles';


const MyProfile = () => {



    const Button = styled(BaseButton)`
    margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
  `;
//const { user } = useIdentityContext();
const user  = getUserInfo();
const stripeId="cus_IhZkmJHgnKKlok";
const full_name = user?.nickname || "Mr.X";

const startBillingDashboard = () => {
    createBillingDashboardSession(stripeId)
        .then(res => res.json())
        .then(data => navigate(data.customer.url))
        .catch(err => console.error(err));
};

const Row = styled(`span`)`
  display: inline-block;
  flex-shrink: 0;
  line-height: 1.3;
  padding-bottom: ${spacing['2xs']}px;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    line-height: 1.4;
    padding-bottom: 0;
  }
`;
return (
    <header className="dashboard-header">
        <Row
            className="mt-4"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: 400,
                    marginBottom: 30,
                }}
            >
              
        <img className="logo-img" src={dashboardLogo} alt="logo"/>
      
            </div>
            <h2 className="m-auto text-center">
                Welcome Back, {full_name.split(' ', 1)}
            </h2>
        </Row>
<div style={{ flex: 1, marginTop: 10 }}>
<Button inverse onClick={e => startBillingDashboard()}>
Your Profile
</Button>
</div>
</header>
    );
};
export default MyProfile;