import React from "react"
import { login, logout, isAuthenticated, getUserInfo } from "../../utils/auth"
import Loading from '../CustomerArea/Loading';

  
  const Profile = () => {
  
    if (Loading || !getUserInfo) {
      return <div>Loading...</div>;
    }
  
    return(
      <>
        <h1>Your profile</h1>
        <img src={user.picture} alt="Profile" />
        <dl>
          <dt>Name</dt>
          <dd>{user.name}</dd>
  
          <dt>Email</dt>
          <dd>{user.email}</dd>
        </dl>
      </>
    )
  }
  
  export default Profile